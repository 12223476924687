import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
// import intl from 'react-intl-universal'
import withI18next from "../components/withI18next"

import Layout from "../components/layout"
import constant from "../constant"
import SEO from "../components/seo"
import { getLink } from "../utils"

const IHeader = styled.header.attrs({ className: "text-white text-center" })`
  height: 730px;
  background: center / cover url(${constant.hBg}) no-repeat;
  margin: 0 auto;
  width: 100%;
  max-width: 1920px;
  position: relative;
  .header-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 90%;
  }
  h1 {
    font-size: ${props => (props.locale === "zh" ? "5rem" : "2rem")};
  }
  .logo-list {
    margin-bottom: 10%;
    .h-logo {
      flex-basis: 45px;
      height: 45px;
      margin-right: 16px;
      margin-bottom: 0;
    }
  }
  .link-button {
    border-radius: 24px;
    width: 190px;
    margin-right: 16px;
    font-size: 14px;
    //padding-top: 15px;
    &:nth-child(2) {
      background: ${constant.active};
      border-color: ${constant.active} !important;
    }
    &:hover {
      background: #82acff;
      border-color: #82acff !important;
    }
  }
  .arrow-down {
    position: absolute;
    bottom: 10%;
    left: 0;
    right: 0;
    margin: auto;
    width: 22px;
    height: 22px;
    /* background: center / contain url('https://cdn.esportseyes.com/arrow-down.gif'); */
    cursor: pointer;
    opacity: 0;
    animation-name: scroll;
    animation-iteration-count: infinite;
    animation-duration: 0.5s;
    animation-direction: alternate;
    @keyframes scroll {
      100% {
        opacity: 1;
        transform: translateY(24px);
      }
    }
  }
`

const IContent = styled.div`
  padding: 80px 0;
  padding-top: 20px;
  margin: 0 auto;
  /* width: 1180px; */
  h2 {
    color: ${constant.subTitle};
    font-size: 34px;
    font-weight: 600;
  }
  .reason {
    margin-bottom: 115px;
    li {
      width: 260px;
    }
    .sub-title {
      font-size: 34px;
      color: ${constant.subTitle};
      white-space: nowrap;
    }
    .desc {
      font-size: 14px;
      color: ${constant.desc};
      text-align-last: center;
    }
  }
  .game-list {
    max-width: 1180px;
    margin: auto;
    @media (max-width: 750px) {
      width: 90%;
      margin: 20px auto;
    }
    .s-game {
      padding: 0px 24px 0px 38px;
      border: none;
      border-radius:12px;
      box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
      transition: box-shadow .3s ease-in-out, transform .3s ease-in-out;
      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 0 0 2px transparent, 0px 9px 10px 0px rgba(0,0,0,0.1);
      }
      .game-logo {
        height: 126px;
        flex-basis: 126px;
        margin-top: 40px;
        margin-bottom: 2em;
        margin-right: 30px;
        border-radius: 100%;
        border: 2px solid ${constant.logoBorder};
        @media (max-width: 750px) {
          margin: 20px auto;
        }
        img {
          height: 80px;
          width: 80px;
        }
      }
      .game-infos {
        flex-basis: 960px;
      }
      .game-info {
        flex-basis: 660px;
        margin-top: 40px;
        margin-bottom: 40px;
        @media (max-width: 750px) {
          margin: 20px auto;
        }
        > * {
          margin-bottom: 10px;
        }
        h3 {
          font-size: 24px;
          font-weight: 600;
        }
        .tag {
          span {
            background: unset;
            color: ${constant.active};
            border: 1px solid;
            font-weight: normal;
          }
        }
        p {
          font-size: 14px;
          line-height: 24px;
          color: ${constant.subTitle};
        }
      }
      .static-link {
        flex-basis: 240px;
        margin-top: 40px;
        margin-bottom: 40px;
        @media (max-width: 750px) {
          margin: 20px auto;
        }
        .league-btn {
          border-radius: 27px;
          position: relative;
          padding-right: 1.5rem;
          white-space: nowrap;
          line-height: 38.5px;
          &:after {
            content: "";
            position: absolute;
            top: 50%;
            width: 16px;
            height: 16px;
            transform: translateY(-50%);
            background: center / contain url(${constant.arrow[1]});
          }
        }
        a {
          font-size: 14px !important;
        }import { ScrollBar } from 'react-perfect-scrollbar';

      }
    }
  }
  .purchaseSteps {
    margin: 0 auto;
    max-width: 1180px;
    min-height: 140px;
    @media (max-width: 750px) {
      margin: 20px auto;
    }
    li {
      padding: 0;
      img {
        width: 100px;
        height: 100px;
        object-fit: contain;
      }
    }
   
    .step {
      min-height: 200px;
      margin-bottom: 50px;
      .content{
        min-height: 200px;
      }
      &:not(:last-child) {
        >.spaceArrow::after {
          content: "------------>";
          display: inline-block;
          color: ${constant.spaceColor};
          margin: 0 10px;
          position: relative;
          top: -50px;
        }
      }
      .desc {
        max-width:  ${props => (props.locale === "zh" ? "120px" : "200px")};
        word-break: keep-all;
        text-align: center;
        text-align-last: center;
        font-size: 1.5rem;
        color: ${constant.subTitle};
        min-height: 80px;
      }
    }
     
  }
`

const PartnersContent = styled.div`
  background: ${constant.partnersBg};
  padding: 36px 0 0 0;
  margin-top: 84px;
  h2 {
    margin-bottom: 75px;
    font-size: 1.5rem;
  }
  .partners {
    margin: 0 auto;
    max-width: 1180px;
    li {
      width: 230px;
      background: ${constant.partnersBg};
      padding: 0px;
      margin-bottom: 80px;
      img {
        height: 80px;
        width: 80px;
        object-fit: contain;
        margin-bottom: 20px;
      }
    }
    .sub-title {
      color: ${constant.subTitle};
      white-space: nowrap;
      font-size: 24px;
      font-weight: 400;
      text-align: center;
    }
  }
`

const IndexPage = ({ pageContext: { locale }, t }) => {
  let timer
  return (
    <Layout>
      <SEO
        title="首页"
        description="猫头鹰数据"
        keywords={[`专业的电竞数据`, `猫头鹰数据`, `电竞数据`, "owlScore"]}
      />
      <IHeader locale={locale}>
        <div className="header-container">
          <h1 className="slogan">{t("slogan")}</h1>
          <p>Comprehensive e-sports data provider</p>
          <ul className="logo-list d-flex justify-content-center">
            {constant.homeLogo.map(logo => (
              <li key={logo} className="h-logo">
                <img src={logo} alt=" " />
              </li>
            ))}
          </ul>
          <ul className="list-group flex-wrap list-group-horizontal justify-content-center">
            {t("api", { returnObjects: true }).map(item => (
              <li
                key={item.desc}
                className="mt-2 btn btn-outline-primary text-white border-white link-button"
              >
                {item.href ? (
                  <a href={item.href} className="text-white d-block">
                    {item.desc}
                  </a>
                ) : (
                  <Link
                    to={getLink(item.to)}
                    className="price text-white d-block"
                  >
                    {item.desc}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
        <a
          // href="#provide"
          onClick={() => {
            const scroll = () => {
              const cur = window.scrollY
              if (cur >= 906) {
                clearInterval(timer)
              } else {
                window.scrollTo(0, cur + 30)
              }
            }
            timer = setInterval(scroll, 1)
          }}
          className="arrow-down"
        >
          <svg
            stroke="#fff"
            fill="#fff"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 129 129"
          >
            <path d="M121.3 34.6c-1.6-1.6-4.2-1.6-5.8 0l-51 51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8 0-1.6 1.6-1.6 4.2 0 5.8l53.9 53.9c.8.8 1.8 1.2 2.9 1.2 1 0 2.1-.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2.1-5.8z"></path>
          </svg>
        </a>
      </IHeader>
      <IContent locale={locale}>
        <h2 className="text-center">{t("why")}</h2>
        <ul className="reason text-center list-group flex-wrap list-group-horizontal justify-content-center">
          {t("reasons", { returnObjects: true }).map(item => (
            <li key={item.icon} className="list-group-item border-0 ">
              <img src={constant[item.icon]} alt="" />
              <p className="sub-title">{item.title}</p>
              <p className="desc">{item.desc}</p>
            </li>
          ))}
        </ul>
        <h2 className="text-center mt-5 mb-5">{t("provide")}</h2>
        <ul className="list-group game-list">
          {t("provideData", { returnObjects: true }).map((item, index) => (
            <li
              key={item.title}
              className="m-b-20 s-game flex-wrap list-group-item justify-content-between d-flex"
            >
              <div className="game-logo d-flex justify-content-center align-items-center">
                <img src={constant.Logo[index]} alt="" />
              </div>
              <div className="game-infos d-flex justify-content-between flex-wrap">
                <div className="game-info">
                  <h3>{item.title}</h3>
                  <div className="tag">
                    {item.type.map(type => (
                      <span
                        className="badge mr-3 badge-pill badge-primary"
                        key={type}
                      >
                        {type}
                      </span>
                    ))}
                  </div>
                  <p>{item.desc}</p>
                </div>
                <div className="static-link d-flex justify-content-center align-items-center">
                  <Link
                    className="web-button league-btn btn btn-primary arrow"
                    to={getLink(item.link)}
                  >
                    {item.linkDesc}
                  </Link>
                </div>
              </div>
            </li>
          ))}
        </ul>

        <PartnersContent>
          <h2 className="text-center">{t("partners")}</h2>
          <ul className="list-group list-group-horizontal d-flex flex-wrap justify-content-center align-items-center partners">
            {constant.partnersData.map(item => (
              <li
                key={item.title}
                className="list-group-item border-0 d-flex justify-content-between flex-wrap flex-column align-items-center"
              >
                <img src={item.imgUrl} alt="" />
                <span className="sub-title">{item.title}</span>
              </li>
            ))}
          </ul>
        </PartnersContent>
        <h2 className="text-center mt-5 mb-5">{t("steps")}</h2>
        <ul className="purchaseSteps list-group list-group-horizontal d-flex flex-wrap justify-content-center align-items-center ">
          {constant.purchaseStep.map((item, index) => {
            return (
              <li
                className="step list-group-item border-0 d-flex flex-row  align-items-center"
                key={Object.keys(item)[0]}
              >
                <div className="content border-0 d-flex flex-column justify-content-between align-items-center">
                  <picture>
                    <img className="stepImg" src={Object.values(item)[0]} />
                  </picture>
                  <span className="desc">
                    {
                      t("stepsDesc", { returnObjects: true })[index][
                        Object.keys(item)[0]
                      ]
                    }
                  </span>
                </div>
                <span className="spaceArrow" />
              </li>
            )
          })}
        </ul>
      </IContent>
    </Layout>
  )
}

export default withI18next({ ns: "common" })(IndexPage)
